<template>
  <FormSection
    :icon="isActive ? 'mdi-account' : 'mdi-account-outline'"
    :is-active="isActive"
    class="assignUser"
    v-on="$listeners"
  >
    <template #title>Apply Users</template>

    <template #description>
      Define and customize access controls for individual users, ensuring secure
      and tailored access to specific repository resources.
    </template>

    <UserSheet
      v-model="visible"
      :mode="modes"
      :repository-id="repositoryId"
      :edit-field="editSecurity"
      :user="user"
      :users="userList"
      @refresh="handleRefresh"
      @reset="reset"
    />

    <!-- fields -->
    <div id="item-list">
      <!-- search -->

      <div class="row">
        <div class="col-12 q-mb-md">
          <div class="row" style="float: right">
            <ExpandableSearch class="q-mr-sm col-auto" @search="searchRow" />

            <BaseActionButton
              v-tooltip.top="'refresh'"
              is-flat
              no-border
              icon="eva-refresh"
              class="col-auto"
              @click="handleRefresh"
            />

            <BaseButton
              icon="eva-plus"
              label="add user"
              class="q-ml-md col-auto"
              @click="add()"
            />
          </div>
        </div>

        <!--  ...  -->
        <div class="col-12">
          <BaseScrollbar class="q-pb-sm" style="max-height: 340px">
            <table>
              <thead>
                <tr>
                  <th class="action"></th>
                  <th>Name</th>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Password</th>
                  <!-- <th>Vault</th>
                  <th>Alert</th>
                  <th>Versions</th> -->
                  <th>Delete</th>
                  <th>Email</th>
                  <th>Print</th>
                  <th>Download</th>
                  <!-- <th>Links</th> -->
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="field in userSecurities" :key="field.id">
                  <td>
                    <div class="row">
                      <BaseActionButton
                        v-tooltip:secondary.left="'edit user'"
                        is-flat
                        color="secondary"
                        icon="eva-edit-outline"
                        no-border
                        @click="edit(field.id)"
                      />
                    </div>
                  </td>
                  <td>{{ field.userName }}</td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.view"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.metadataEdit"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.password"
                      :is-disabled="true"
                    />
                  </td>
                  <!-- <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.vault"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.alert"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.checkInAndOut"
                      :is-disabled="true"
                    />
                  </td> -->
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.deleteFile"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.emailSharing"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.print"
                      :is-disabled="true"
                    />
                  </td>
                  <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.download"
                      :is-disabled="true"
                    />
                  </td>
                  <!-- <td>
                    <CheckboxField
                      is-readonly
                      :value="field.options.links"
                      :is-disabled="true"
                    />
                  </td> -->
                  <td>
                    <div class="row">
                      <BaseActionButton
                        v-tooltip:red.right="'delete user'"
                        is-flat
                        color="red"
                        icon="eva-close-outline"
                        no-border
                        @click="deleteSecurity(field.id)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </BaseScrollbar>
        </div>
      </div>
    </div>
    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import UserSheet from "./users/UserSheet.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";

import { repository, user } from "@/api/factory.js";

export default {
  name: "AssignUsers",

  components: {
    FormSection,
    CheckboxField,
    UserSheet,
    ExpandableSearch,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    repositoryId: {
      type: String,
      default: "",
    },

    user: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
      modes: "",
      userSecurities: [],
      editSecurity: {},
      securityData: [],
      userList: [],
    };
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        if (this.user) {
          this.userSecurities = this.user;
        }
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    handleRefresh(id) {
      this.$emit("refresh", id);
    },

    reset() {
      this.editSecurity = {};
    },

    add() {
      this.modes = "ADD";
      this.visible = true;
    },

    edit(id) {
      this.editSecurity = this.userSecurities.find(
        (security) => security.id === id
      );

      this.modes = "EDIT";
      this.visible = true;
    },

    searchRow(search) {
      this.securityData = this.user;
      if (search) {
        this.userSecurities = this.securityData.filter((item) => {
          return item.userName.toLowerCase().includes(search.toLowerCase());
        });
      } else {
        this.userSecurities = this.securityData;
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: user.id,
        }));
    },

    async deleteSecurity(id) {
      const { error } = await repository.deleteRepositorySecurity(id);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Folder security deleted succesfully");
      this.handleRefresh(this.repositoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
#item-list {
  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          text-align: left;
          font-weight: normal;
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        td {
          padding: 8px;
          height: 52px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#form-section.assignUser {
  #form {
    width: 60vw !important;
  }
}
</style>
