<template>
  <Sheet :value="value" has-footer no-padding width="880px" @input="closeSheet">
    <!-- title -->

    <template #title> {{ name }} Rules / Conditions </template>

    <!-- ... -->

    <!-- rules & conditions -->

    <template #default>
      <div id="tab-settings" class="row">
        <div class="content">
          <BaseScrollbar height="calc(100vh - 120px)">
            <!-- description -->

            <div class="q-my-lg text-bold">
              Define rules or conditions for the folder or document security
            </div>

            <!-- ... -->

            <!-- condition -->

            <template v-if="activeTab === 'CONDITIONS'">
              <ValidationProvider
                v-slot="{ errors }"
                name="rule name"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="security.name"
                  label="rule name"
                  is-mandatory
                  :error="errors[0]"
                  class="q-mb-lg"
                />
              </ValidationProvider>

              <template v-if="nameError">
                <div class="q-mb-lg text-red">
                  Rule name should not be empty
                </div>
              </template>

              <template v-for="(group, groupIdx) in security.filterBy">
                <div :key="group.id" class="filter-group">
                  <!-- header -->

                  <div class="header">
                    <div class="label q-mr-md">Criteria</div>
                    <div class="label q-mr-md">Condition</div>
                    <div class="label q-mr-sm">Value</div>

                    <BaseActionButton
                      is-flat
                      color="secondary"
                      icon="eva-plus"
                      no-border
                      @click="addFilter(groupIdx)"
                    />
                  </div>

                  <!-- ... -->

                  <BaseSeparator has-inset class="q-mb-md" />

                  <!-- security -->

                  <ValidationObserver
                    v-for="(filter, filterIdx) in group.filters"
                    ref="form"
                    :key="filter.id"
                    class="filter"
                  >
                    <!-- criteria -->

                    <ValidationProvider
                      :key="filter.id"
                      v-slot="{ errors }"
                      name="criteria"
                      :rules="{ required: true }"
                      class="col q-mr-md"
                    >
                      <SelectField
                        v-model="filter.criteria"
                        is-mandatory
                        :is-searchable="true"
                        :options="_columns"
                        :error="errors[0]"
                        @input="
                          applyDataType(groupIdx, filterIdx, filter.criteria)
                        "
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- condition -->

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="condition"
                      :rules="{ required: true }"
                      class="col q-mr-md"
                    >
                      <SelectField
                        v-model="filter.condition"
                        is-mandatory
                        :is-searchable="true"
                        :options="conditions(filter.criteria)"
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- value -->

                    <ValidationProvider
                      v-if="
                        filter.dataType === 'SHORT_TEXT' ||
                        filter.dataType === 'BARCODE'
                      "
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <TextField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-else-if="filter.dataType === 'NUMBER'"
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <NumberField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-else-if="filter.dataType === 'DATE'"
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <DateField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-else-if="filter.dataType === 'TIME'"
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <TimeField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-else-if="filter.dataType === 'SINGLE_SELECT'"
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <template v-if="checkOptions(filter.criteria)">
                        <AsyncMultiSelectField
                          v-model="filter.arrayValue"
                          is-mandatory
                          :is-disabled="
                            ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                              filter.condition
                            )
                          "
                          :error="errors[0]"
                          :api-path="apiPath"
                          :column-name="filter.criteria"
                          new-option
                        />
                        <AsyncSelectField
                          v-if="false"
                          v-model="filter.value"
                          is-mandatory
                          :is-disabled="
                            ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                              filter.condition
                            )
                          "
                          :error="errors[0]"
                          :api-path="apiPath"
                          :column-name="filter.criteria"
                          new-option
                        />
                      </template>
                      <template v-else>
                        <SelectField
                          v-if="false"
                          v-model="filter.value"
                          is-mandatory
                          :is-disabled="
                            ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                              filter.condition
                            )
                          "
                          :is-searchable="true"
                          :options="getOptions(filter.criteria)"
                          :error="errors[0]"
                          new-option
                        />
                        <MultiSelectField
                          v-model="filter.value"
                          is-mandatory
                          :is-disabled="
                            ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                              filter.condition
                            )
                          "
                          :is-searchable="true"
                          :options="getOptions(filter.criteria)"
                          :error="errors[0]"
                          new-option
                        />
                      </template>
                    </ValidationProvider>

                    <ValidationProvider
                      v-else
                      v-slot="{ errors }"
                      name="value"
                      :rules="{
                        required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                          filter.condition
                        ),
                      }"
                      class="col q-mr-sm"
                    >
                      <TextField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- remove -->

                    <div class="remove-filter">
                      <BaseActionButton
                        is-flat
                        color="red"
                        no-border
                        icon="eva-close-outline"
                        @click="removeFilter(groupIdx, filterIdx)"
                      />
                    </div>

                    <!-- ... -->
                  </ValidationObserver>

                  <!-- ... -->

                  <BaseSeparator has-inset />

                  <div class="row">
                    <Action
                      icon="eva-plus-outline"
                      label="Add Condition"
                      class="col"
                      @click="addFilterGroup(groupIdx + 1)"
                    />

                    <BaseSeparator is-vertical has-inset />

                    <Action
                      icon="eva-trash-2-outline"
                      label="Delete Condition"
                      class="col"
                      @click="removeFilterGroup(groupIdx)"
                    />
                  </div>
                </div>

                <div
                  v-if="groupIdx !== security.filterBy.length - 1"
                  :key="groupIdx"
                  class="row justify-center"
                >
                  <Toggle
                    v-model="group.groupCondition"
                    :options="groupConditions"
                    class="q-my-md"
                    @input="groupCondition"
                  />
                </div>
              </template>
            </template>

            <!-- ... -->

            <!-- user & group security -->

            <template v-if="activeTab === 'SECURITY'">
              <MultiSelectField
                v-model="security.userId_Array"
                label="users"
                is-mandatory
                :options="userList"
                class="q-mt-lg"
              />

              <MultiSelectField
                v-model="security.groupId_Array"
                label="groups"
                is-mandatory
                :options="groupList"
                class="q-mt-lg"
              />

              <template v-if="user_grpError">
                <div class="q-mt-lg text-red">
                  User or Group list should not be empty
                </div>
              </template>

              <SingleChoiceField
                v-model="security.visibility"
                is-mandatory
                label="Visibility"
                :options-per-line="1"
                :options="options"
                class="q-mt-lg"
              />

              <template v-if="visibilityError">
                <div class="q-mt-lg text-red">
                  Visibility should not be empty
                </div>
              </template>
            </template>

            <!-- ... -->
          </BaseScrollbar>
        </div>

        <!-- tabs -->

        <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? 'primary' : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="clear"
        class="q-mr-sm"
        :disabled="fileSecurity.length"
        @click="clearFilter(true)"
      />

      <BaseButton :label="label" @click="mode === 'ADD' ? apply() : save()" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { lowerCase, startCase } from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import Action from "@/components/common/Action.vue";
import Toggle from "@/components/common/Toggle.vue";
import Conditions from "@/helpers/conditions.js";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import AsyncMultiSelectField from "@/components/common/form/async-select-field/AsyncMultiSelectField.vue";

import { user, group, repository } from "@/api/factory.js";
export default {
  name: "Security",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
    MultiSelectField,
    SingleChoiceField,
    Action,
    Toggle,
    NumberField,
    DateField,
    TimeField,
    AsyncSelectField,
    AsyncMultiSelectField,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },

    mode: {
      type: String,
      default: "",
    },

    columns: {
      type: Array,
      required: true,
    },

    value: {
      type: Boolean,
      default: false,
    },

    fileSecurity: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      groupConditions: ["AND", "OR"],
      userList: [],
      groupList: [],
      options: [
        {
          id: this.$nano.id(),
          label: "Show only",
          value: "SHOW",
        },
        {
          id: this.$nano.id(),
          label: "Hide only",
          value: "HIDE",
        },
      ],
      activeTab: "CONDITIONS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "conditions",
          value: "CONDITIONS",
          icon: "mdi-tune",
        },
        {
          id: this.$nano.id(),
          label: "security",
          value: "SECURITY",
          icon: "mdi-shield-account-outline",
        },
      ],
      security: {
        id: 0,
        repositoryId: this.repositoryId,
        name: "",
        conditions: [],
        filterBy: [],
        userId_Array: [],
        groupId_Array: [],
        visibility: "",
      },
      name: "",
      nameError: false,
      user_grpError: false,
      visibilityError: false,
    };
  },

  computed: {
    _columns() {
      var data = this.columns.reduce((ids, column) => {
        if ("isFilter" in column) {
          if (column.isFilter) {
            ids.push({
              id: column.id,
              label: column.name,
              value: column.name,
              dataType: column.dataType,
              levelId: column.level,
              icon: this.getIcon(column.level),
              color: this.getColor(column.level),
            });
          }
        } else {
          ids.push({
            id: column.id,
            label: column.name,
            value: column.name,
            dataType: column.dataType,
            levelId: column.level,
            icon: this.getIcon(column.level),
            color: this.getColor(column.level),
          });
        }
        return ids;
      }, []);
      return data;
      // return this.columns.map((column) => ({
      //   id: column.id,
      //   label: column.label,
      //   value: column.name,
      // }));
    },

    apiPath() {
      return `/repository/${this.repositoryId}/uniqueColumnValues`;
    },

    label() {
      if (this.mode === "ADD") {
        return "Apply";
      } else return "Save";
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        if (this.value) {
          if (Object.keys(this.fileSecurity).length !== 0) {
            this.security = this.fileSecurity;
            for (
              let groupIdx = 0;
              groupIdx < this.security.filterBy.length;
              groupIdx++
            ) {
              const group = this.security.filterBy[groupIdx];

              for (
                let filterIdx = 0;
                filterIdx < group.filters.length;
                filterIdx++
              ) {
                const filter = group.filters[filterIdx];
                const name = filter.criteria;
                const value = filter.value;

                this.applyDataType(groupIdx, filterIdx, name, value);
              }
            }
            if (this.security.filterBy.length > 1) {
              this.security.filterBy[0].groupCondition =
                this.security.filterBy[1].groupCondition;
            } else this.security.filterBy[0].groupCondition = "";
          } else {
            this.security.filterBy = [];
            this.addFilterGroup(0);
          }
        }
      },
    },

    mode: {
      immediate: true,
      handler() {
        if (this.mode === "ADD") {
          this.name = "Add";
        } else if (this.mode === "EDIT") {
          this.name = "Edit";
        }
      },
    },
  },

  mounted() {
    this.getUserList();
    this.getGroupList();
  },

  methods: {
    conditions(columnName) {
      if (!columnName) {
        return [];
      }

      const dataType = this.columns.find(
        (column) => column.name === columnName
      );
      if (dataType) {
        return Conditions(dataType.dataType).map((condition) => ({
          id: this.$nano.id(),
          label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
            condition.split(" ")[1] ? condition.split(" ")[1] : ""
          }`,
          value: condition.split(" ")[0],
        }));
      }
    },

    addFilterGroup(groupIdx = 0) {
      this.security.filterBy.splice(groupIdx, 0, {
        id: this.$nano.id(),
        filters: [
          {
            id: this.$nano.id(),
            criteria: "",
            condition: "",
            value: "",
            levelId: "",
          },
        ],
        groupCondition: this.security.filterBy.length ? "" : "",
      });
    },

    removeFilterGroup(groupIdx) {
      if (groupIdx > 0) {
        this.security.filterBy.splice(groupIdx, 1);
      } else {
        this.$emit("input", false);
      }
    },

    addFilter(groupIdx) {
      this.security.filterBy[groupIdx].filters.push({
        id: this.$nano.id(),
        criteria: "",
        condition: "",
        value: "",
        levelId: "",
      });
    },

    removeFilter(groupIdx, filterIdx) {
      this.security.filterBy[groupIdx].filters.splice(filterIdx, 1);
    },

    applyDataType(groupIdx, filterIdx, name, value) {
      this.security.filterBy[groupIdx].filters[filterIdx].value = "";
      this.security.filterBy[groupIdx].filters[filterIdx].condition = "";
      this.security.filterBy[groupIdx].filters[filterIdx].condition =
        "IS_EQUALS_TO";

      this.security.filterBy[groupIdx].filters[filterIdx].dataType = "";

      let type = this._columns.find((item) => item.label === name);

      if (type) {
        this.security.filterBy[groupIdx].filters[filterIdx].dataType =
          type.dataType;
        this.security.filterBy[groupIdx].filters[filterIdx].levelId =
          type.levelId;
        if (value) {
          this.security.filterBy[groupIdx].filters[filterIdx].value = value;
        }
      }
    },

    clearFilter(boolean) {
      this.security.name = "";
      this.security.filterBy = [];
      this.security.conditions = [];
      this.security.userId_Array = [];
      this.security.groupId_Array = [];
      this.security.visibility = "";
      this.activeTab = "CONDITIONS";

      if (boolean) {
        this.addFilterGroup();
      }
    },

    groupCondition(condition) {
      this.security.filterBy[this.security.filterBy.length - 1].groupCondition =
        condition;
    },

    checkOptions(name) {
      let column = this.columns.find((column) => column.name === name);
      if (column) {
        if (column.apiLoad) {
          return true;
        }
      }
      return false;
    },

    getOptions(name) {
      if (
        name === "createdBy" ||
        name === "modifiedBy" ||
        name === "owner" ||
        name === "coordinator"
      ) {
        return this.userList;
      }
      return [];
    },

    getIcon(id) {
      let columns = this.columns;
      let largestValue = 0;
      let icon = "";
      for (const column of columns) {
        if (column.level > largestValue) {
          largestValue = column.level;
        }
      }

      if (id !== 0 && id < largestValue) {
        icon = "mdi-folder";
      } else if (id == largestValue) {
        icon = "eva-file-text-outline";
      } else icon = "mdi-label-outline";

      return icon;
    },

    getColor(id) {
      let columns = this.columns;

      let largestValue = 0;

      let color = "";

      for (const column of columns) {
        if (column.level > largestValue) {
          largestValue = column.level;
        }
      }

      if (id !== 0 && id < largestValue) {
        color = "amber";
      } else if (id == largestValue) {
        color = "secondary";
      }

      return color;
    },

    closeSheet() {
      this.$emit("input", false);
      this.clearFilter(false);
      this.$emit("refresh", this.repositoryId);
      this.$emit("reset");
    },

    async getUserList() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: user.id,
          // value: parseInt(user.id, 10),
        }));
    },

    async getGroupList() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
          // value: parseInt(group.id, 10),
        }));
    },

    async apply() {
      let invalidFields = 0;

      if (!this.$refs.form) {
        return;
      }

      for (const form of this.$refs.form) {
        const isValid = await form.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }

      this.security.filterBy[0].groupCondition = "";

      if (this.security.filterBy.length) {
        if (!this.security.filterBy[0].filters.length) {
          this.security.filterBy = [];
        }
        this.security.filterBy.forEach((group) => {
          if (group.filters.length) {
            group.filters.forEach((row) => {
              if (row.dataType === "SINGLE_SELECT") {
                row.value = JSON.stringify(row.arrayValue);
              }
            });
          }
        });
      }

      this.security.conditions = this.security.filterBy;

      if (
        this.security.name &&
        (this.security.userId_Array.length ||
          this.security.groupId_Array.length) &&
        this.security.visibility
      ) {
        const { error } = await repository.insertDocumentSecurity(
          this.security
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("New document security added succesfully");
        this.$emit("refresh", this.repositoryId);

        this.clearFilter(false);
      } else {
        if (!this.security.name) {
          this.activeTab = "CONDITIONS";
          this.nameError = true;
        } else if (
          !this.security.userId_Array.length &&
          !this.security.groupId_Array.length
        ) {
          this.activeTab = "SECURITY";
          this.nameError = false;
          this.user_grpError = true;
        } else if (!this.security.visibility) {
          this.activeTab = "SECURITY";
          this.user_grpError = false;
          this.visibilityError = true;
        }
      }
    },

    async save() {
      let invalidFields = 0;

      if (!this.$refs.form) {
        return;
      }

      for (const form of this.$refs.form) {
        const isValid = await form.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }

      this.security.filterBy[0].groupCondition = "";

      if (this.security.filterBy.length) {
        if (!this.security.filterBy[0].filters.length) {
          this.security.filterBy = [];
        }
      }

      this.security.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify(row.arrayValue);
            }
          });
        }
      });

      this.security.conditions = this.security.filterBy;

      if (
        this.security.name &&
        (this.security.userId_Array.length ||
          this.security.groupId_Array.length) &&
        this.security.visibility
      ) {
        let id = this.security.id;
        const { error } = await repository.updateDocumentSecurity(
          id,
          this.security
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("Document security updated succesfully");
        this.$emit("refresh", this.repositoryId);

        this.clearFilter(false);
      } else {
        if (!this.security.name) {
          this.activeTab = "CONDITIONS";
          this.nameError = true;
        } else if (
          !this.security.userId_Array.length &&
          !this.security.groupId_Array.length
        ) {
          this.activeTab = "SECURITY";
          this.nameError = false;
          this.user_grpError = true;
        } else if (!this.security.visibility) {
          this.activeTab = "SECURITY";
          this.user_grpError = false;
          this.visibilityError = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#tab-settings {
  // .field {
  //   margin: 16px 16px 24px 16px;
  // }

  .content {
    flex: 1;
    padding: 0px 16px 8px 24px;
    border-right: 1px solid var(--divider-color);
  }

  .filter-group {
    border: 1px solid var(--divider-color);
    border-radius: 4px;

    .header {
      display: flex;
      align-items: center;
      padding: 8px 8px 8px 16px;

      .label {
        @extend .text-sm;
        color: var(--icon-color);
        flex: 1;
      }
    }

    .filter {
      display: flex;
      padding: 0px 8px 16px 16px;

      :deep #field div {
        width: 137px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 0px;
      }
    }

    .remove-filter {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      color: var(--secondary);
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tabs {
    width: 68px;
    padding: 16px;
  }
}
</style>
