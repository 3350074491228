<template>
  <Sheet :value="value" has-footer no-padding width="880px" @input="closeSheet">
    <!-- title -->

    <template #title> {{ name }} Retention </template>

    <!-- ... -->

    <!-- rules & conditions -->

    <template #default>
      <div id="tab-settings" class="row">
        <div class="content">
          <!-- <BaseScrollbar height="calc(100vh - 120px)"> -->
          <!-- description -->

          <div class="text-bold q-mb-md">
            Define conditions for the document retention
          </div>

          <!-- ... -->

          <!-- condition -->

          <div v-show="activeTab === 'CONDITIONS'">
            <ValidationObserver ref="form">
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Rule name"
                    :rules="{ required: true }"
                  >
                    <TextField
                      v-model="security.name"
                      label="Rule name"
                      is-mandatory
                      :error="errors[0]"
                      class="q-mb-md"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Retention field"
                    :rules="{ required: true }"
                  >
                    <SelectField
                      v-model="security.fieldId"
                      label="Retention Field"
                      is-mandatory
                      :options="getDateField"
                      :error="errors[0]"
                      class="q-mb-md"
                    />
                  </ValidationProvider>
                </div>
              </div>

              <SingleChoiceField
                v-model="notificationDate"
                is-mandatory
                label="Set Notification Date"
                :options-per-line="2"
                :options="notificationDateOptions"
                class="q-mb-md"
              />

              <template v-if="notificationDate === 'field_date'">
                <FormFieldLabel
                  :label="getFieldDateLabel"
                  class="q-mb-md"
                  transform=""
                />
                <BaseSeparator class="q-mb-md" />
              </template>
              <!-- <template
                  v-if="
                    notificationDate === 'specific_date'
                  "
                >
                  <FormFieldLabel
                    label="Allows you to  define a particular date for document retention, activating automatic reminders when this chosen date is reached"
                    class="q-mb-lg"
                  />

                  <DateField
                    v-model="security.notification.reminderDate"
                    label="reminder date"
                    class="q-mt-md"
                  />
                </template> -->

              <div
                v-if="notificationDate === 'future_date'"
                class="row q-col-gutter-md"
              >
                <div class="col-12">
                  <FormFieldLabel
                    label="Allows to schedule a future expiry date
                based on an existing date, which prompts automatic reminders
                when due"
                    class="q-mb-md"
                    transform=""
                  />
                  <BaseSeparator />
                </div>

                <!-- <div class="col-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Retention field"
                      :rules="{ required: true }"
                    >
                      <SelectField
                        v-model="security.fieldId"
                        label="Retention Field"
                        is-mandatory
                        :options="getDateField"
                        :error="errors[0]"
                        class="q-mb-md"
                      />
                    </ValidationProvider>
                  </div> -->
                <div class="col-6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Duration"
                    :rules="validateUploadDateRules"
                  >
                    <CounterField
                      v-model="security.duration"
                      label="Duration"
                      :is-mandatory="hasUploadDate"
                      :error="errors[0]"
                      class="q-mb-md"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Duration Type"
                    :rules="validateUploadDateRules"
                  >
                    <SelectField
                      v-model="security.durationType"
                      label="Duration Type"
                      :is-mandatory="hasUploadDate"
                      :options="durationTypes"
                      :error="errors[0]"
                      class="q-mb-md"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>

            <template v-for="(group, groupIdx) in security.filterBy">
              <div :key="group.id" class="filter-group">
                <!-- header -->

                <div class="header">
                  <div class="label q-mr-md">Criteria</div>
                  <div class="label q-mr-md">Condition</div>
                  <div class="label q-mr-sm">Value</div>

                  <BaseActionButton
                    is-flat
                    color="secondary"
                    icon="eva-plus"
                    no-border
                    @click="addFilter(groupIdx)"
                  />
                </div>

                <!-- ... -->

                <BaseSeparator has-inset class="q-mb-md" />

                <!-- security -->

                <ValidationObserver
                  v-for="(filter, filterIdx) in group.filters"
                  ref="condition"
                  :key="filter.id"
                  class="filter"
                >
                  <!-- criteria -->

                  <ValidationProvider
                    :key="filter.id"
                    v-slot="{ errors }"
                    name="criteria"
                    :rules="{ required: true }"
                    class="col q-mr-md"
                  >
                    <SelectField
                      v-model="filter.criteria"
                      is-mandatory
                      :is-searchable="true"
                      :options="_columns"
                      :error="errors[0]"
                      @input="
                        applyDataType(groupIdx, filterIdx, filter.criteria)
                      "
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- condition -->

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="condition"
                    :rules="{ required: true }"
                    class="col q-mr-md"
                  >
                    <SelectField
                      v-model="filter.condition"
                      is-mandatory
                      :is-searchable="true"
                      :options="conditions(filter.criteria)"
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- value -->

                  <ValidationProvider
                    v-if="
                      filter.dataType === 'SHORT_TEXT' ||
                      filter.dataType === 'BARCODE'
                    "
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <TextField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'NUMBER'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <NumberField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'DATE'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <DateField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'TIME'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <TimeField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'SINGLE_SELECT'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <template v-if="checkOptions(filter.criteria)">
                      <AsyncMultiSelectField
                        v-model="filter.arrayValue"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                        :api-path="apiPath"
                        :column-name="filter.criteria"
                        new-option
                      />
                      <AsyncSelectField
                        v-if="false"
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                        :api-path="apiPath"
                        :column-name="filter.criteria"
                        new-option
                      />
                    </template>
                    <template v-else>
                      <SelectField
                        v-if="false"
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :is-searchable="true"
                        :options="getOptions(filter.criteria)"
                        :error="errors[0]"
                        new-option
                      />
                      <MultiSelectField
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :is-searchable="true"
                        :options="getOptions(filter.criteria)"
                        :error="errors[0]"
                        new-option
                      />
                    </template>
                  </ValidationProvider>

                  <ValidationProvider
                    v-else
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <TextField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- remove -->

                  <div class="remove-filter">
                    <BaseActionButton
                      is-flat
                      color="red"
                      no-border
                      icon="eva-close-outline"
                      @click="removeFilter(groupIdx, filterIdx)"
                    />
                  </div>

                  <!-- ... -->
                </ValidationObserver>

                <!-- ... -->

                <BaseSeparator has-inset />

                <div class="row">
                  <Action
                    icon="eva-plus-outline"
                    label="Add Condition"
                    class="col"
                    @click="addFilterGroup(groupIdx + 1)"
                  />

                  <BaseSeparator is-vertical has-inset />

                  <Action
                    icon="eva-trash-2-outline"
                    label="Delete Condition"
                    class="col"
                    @click="removeFilterGroup(groupIdx)"
                  />
                </div>
              </div>

              <div
                v-if="groupIdx !== security.filterBy.length - 1"
                :key="groupIdx"
                class="row justify-center"
              >
                <Toggle
                  v-model="group.groupCondition"
                  :options="groupConditions"
                  class="q-my-md"
                  @input="groupCondition"
                />
              </div>
            </template>
          </div>

          <!-- ... -->

          <!-- user & group security -->

          <div v-show="activeTab === 'NOTIFICATION'">
            <ValidationObserver ref="security">
              <ValidationProvider
                v-slot="{ errors }"
                name="Retention Action"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="security.action"
                  label="Retention Action"
                  is-mandatory
                  :options="retentionActions"
                  :error="errors[0]"
                  class="q-mb-md"
                />
              </ValidationProvider>

              <FormFieldLabel
                label="Pre-Expiration Notification for file Retention"
              />

              <BaseSeparator class="q-mb-md" />

              <CheckboxField
                v-model="notify"
                label="Notify via Email"
                description=""
                class="q-mb-md"
              />
              <template v-if="notify">
                <div class="row q-col-gutter-md">
                  <div class="col-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Before Counts"
                      :rules="{ required: notify }"
                    >
                      <CounterField
                        v-model="security.notification.duration"
                        label="Before the retention policy"
                        :is-mandatory="notify"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="col-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Duration Type"
                      :rules="{ required: notify }"
                    >
                      <SelectField
                        v-model="security.notification.durationType"
                        label="Duration Type"
                        :is-mandatory="notify"
                        :options="durationTypes"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="user"
                      :rules="{
                        required: security.notification.group.length === 0,
                      }"
                    >
                      <MultiSelectField
                        v-model="security.notification.user"
                        label="users"
                        :options="userList"
                        class="q-mt-md"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="col-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="group"
                      :rules="{
                        required: security.notification.user.length === 0,
                      }"
                    >
                      <MultiSelectField
                        v-model="security.notification.group"
                        label="groups"
                        :options="groupList"
                        class="q-mt-md"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </div>
                <FormFieldLabel label="Mail Subject" class="q-mt-lg" />
                <TextEditor
                  v-model="security.notification.mailSubject"
                  :hide-tool-bar="false"
                  :form-columns="fieldColumns"
                  class="mail-class"
                ></TextEditor>

                <FormFieldLabel label="Mail Content" class="q-my-md" />
                <TextEditor
                  v-model="security.notification.mailBody"
                  :form-columns="fieldColumns"
                  class="mail-class q-pb-md"
                ></TextEditor>
              </template>
            </ValidationObserver>
          </div>

          <!-- ... -->
          <!-- </BaseScrollbar> -->
        </div>

        <!-- tabs -->

        <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? 'primary' : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="clear"
        class="q-mr-sm"
        :disabled="retentionSecurity.length"
        @click="clearFilter(true)"
      />

      <BaseButton :label="label" @click="mode === 'ADD' ? apply() : save()" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { lowerCase, startCase } from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import Action from "@/components/common/Action.vue";
import Toggle from "@/components/common/Toggle.vue";
import Conditions from "@/helpers/conditions.js";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import AsyncMultiSelectField from "@/components/common/form/async-select-field/AsyncMultiSelectField.vue";
import { user, group, repository } from "@/api/factory.js";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextEditor from "@/components/common/text-editor-with-columns/TextEditor";
export default {
  name: "RetentionSettings",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
    MultiSelectField,
    SingleChoiceField,
    Action,
    Toggle,
    NumberField,
    DateField,
    TimeField,
    AsyncSelectField,
    AsyncMultiSelectField,
    CounterField,
    CheckboxField,
    FormFieldLabel,
    TextEditor,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },

    mode: {
      type: String,
      default: "",
    },

    columns: {
      type: Array,
      required: true,
    },

    value: {
      type: Boolean,
      default: false,
    },

    retentionSecurity: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      groupConditions: ["AND", "OR"],
      userList: [],
      groupList: [],
      options: [
        {
          id: this.$nano.id(),
          label: "Show only",
          value: "SHOW",
        },
        {
          id: this.$nano.id(),
          label: "Hide only",
          value: "HIDE",
        },
      ],
      activeTab: "CONDITIONS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "conditions",
          value: "CONDITIONS",
          icon: "mdi-tune",
        },
        {
          id: this.$nano.id(),
          label: "notification",
          value: "NOTIFICATION",
          icon: "mdi-bell-outline",
        },
      ],
      security: {
        id: 0,
        repositoryId: this.repositoryId,
        itemId: 0,
        name: "",
        fieldId: "",
        duration: 0,
        durationType: "Day",
        filterBy: [],
        conditionJson: [],
        action: "",
        actionDate: "",
        notification: {
          duration: 0,
          durationType: "Day",
          user: [],
          group: [],
          mailSubject: {
            text: "",
            content: "",
          },
          mailBody: {
            text: "",
            content: "",
          },
        },
      },
      name: "",
      nameError: false,
      user_grpError: false,
      visibilityError: false,
      durationTypes: [
        {
          id: this.$nano.id(),
          label: "Year",
          value: "Year",
        },
        {
          id: this.$nano.id(),
          label: "Month",
          value: "Month",
        },
        {
          id: this.$nano.id(),
          label: "Day",
          value: "Day",
        },
      ],
      retentionActions: [
        {
          id: this.$nano.id(),
          label: "No Action",
          value: "NO_ACTION",
        },
        {
          id: this.$nano.id(),
          label: "Hide",
          value: "HIDE",
        },
        {
          id: this.$nano.id(),
          label: "Move to Trash",
          value: "TRASH",
        },
        {
          id: this.$nano.id(),
          label: "Permanent Delete",
          value: "DELETE",
        },
      ],
      notify: false,

      notificationDateOptions: [
        {
          id: this.$nano.id(),
          label: "Use Field Date",
          value: "field_date",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Use Specific Date",
        //   value: "specific_date",
        // },
        {
          id: this.$nano.id(),
          label: "Set Future Date from Field Date",
          value: "future_date",
        },
      ],
      notificationDate: "field_date",
    };
  },

  computed: {
    _columns() {
      var data = this.columns.reduce((ids, column) => {
        if ("isFilter" in column) {
          if (column.isFilter) {
            ids.push({
              id: column.id,
              label: column.name,
              value: column.name,
              dataType: column.dataType,
              levelId: column.level,
              icon: this.getIcon(column.level),
              color: this.getColor(column.level),
            });
          }
        } else {
          ids.push({
            id: column.id,
            label: column.name,
            value: column.name,
            dataType: column.dataType,
            levelId: column.level,
            icon: this.getIcon(column.level),
            color: this.getColor(column.level),
          });
        }
        return ids;
      }, []);
      return data;
      // return this.columns.map((column) => ({
      //   id: column.id,
      //   label: column.label,
      //   value: column.name,
      // }));
    },

    apiPath() {
      return `/repository/${this.repositoryId}/uniqueColumnValues`;
    },

    label() {
      if (this.mode === "ADD") {
        return "Apply";
      } else return "Save";
    },

    getDateField() {
      let field = [
        {
          id: this.$nano.id(),
          label: "Uploaded Date",
          value: "0",
        },
      ];
      this.columns.forEach((column) => {
        if (column.dataType === "DATE") {
          field.push({
            id: this.$nano.id(),
            label: column.name,
            value: column.id,
          });
        }
      });
      return field;
    },

    hasUploadDate() {
      if (!this.security.fieldId || this.security.fieldId === "0") {
        return true;
      }
      return false;
    },

    validateUploadDateRules() {
      let validate = { required: false };
      if (this.hasUploadDate) {
        return { required: true };
      }

      return validate;
    },

    getFieldDateLabel() {
      if (this.security.fieldId) {
        let field = this.getDateField.find(
          (item) => item.value === this.security.fieldId
        );
        return `Set a document's expiry based on
                    an <${field.label}>, which triggers auto-reminders when due`;
      }
      return "";
    },

    fieldColumns() {
      let fields = [
        {
          id: this.$nano.id(),
          label: "Uploaded Date",
          value: "#UPLOADEDDATE#",
          display: "",
          color: "#643094",
          hasDefault: true,
        },
        {
          id: this.$nano.id(),
          label: "Uploaded By",
          value: "#UPLOADBY#",
          display: "",
          color: "#643094",
          hasDefault: true,
        },
      ];
      let columns = this.columns.map((column) => ({
        id: column.id,
        label: column.name,
        value: column.id,
        hasDefault: false,
      }));

      return [...fields, ...columns];
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        if (this.value) {
          if (Object.keys(this.retentionSecurity).length !== 0) {
            this.security.id = this.retentionSecurity.id;
            this.security.name = this.retentionSecurity.name;
            this.security.fieldId = !this.retentionSecurity.fieldId
              ? "0"
              : this.retentionSecurity.fieldId;
            this.security.duration = this.retentionSecurity.duration;
            this.security.durationType = this.retentionSecurity.durationType;
            this.security.conditionJson = this.retentionSecurity.conditionJson;
            if (this.security.duration) {
              this.notificationDate = "future_date";
            }
            this.security.filterBy = JSON.parse(
              this.retentionSecurity.conditionJson
            );
            this.security.action = this.retentionSecurity.action;
            if (this.retentionSecurity.notifyJson) {
              this.security.notification = JSON.parse(
                this.retentionSecurity.notifyJson
              );
              this.notify = true;
            }

            this.security.filterBy.forEach((group) => {
              if (group.filters.length) {
                group.filters.forEach((row) => {
                  if (row.dataType === "SINGLE_SELECT") {
                    row.arrayValue = JSON.parse(row.value);
                  }
                });
              }
            });

            for (
              let groupIdx = 0;
              groupIdx < this.security.filterBy.length;
              groupIdx++
            ) {
              const group = this.security.filterBy[groupIdx];

              for (
                let filterIdx = 0;
                filterIdx < group.filters.length;
                filterIdx++
              ) {
                const filter = group.filters[filterIdx];
                const name = filter.criteria;
                const value = filter.value;

                this.applyDataType(groupIdx, filterIdx, name, value);
              }
            }
            if (this.security.filterBy.length > 1) {
              this.security.filterBy[0].groupCondition =
                this.security.filterBy[1].groupCondition;
            } else this.security.filterBy[0].groupCondition = "";
          } else {
            this.security.filterBy = [];
            this.addFilterGroup(0);
          }
        }
      },
    },

    mode: {
      immediate: true,
      handler() {
        if (this.mode === "ADD") {
          this.name = "Add";
        } else if (this.mode === "EDIT") {
          this.name = "Edit";
        }
      },
    },
  },

  mounted() {
    this.getUserList();
    this.getGroupList();
  },

  methods: {
    conditions(columnName) {
      if (!columnName) {
        return [];
      }

      const dataType = this.columns.find(
        (column) => column.name === columnName
      );
      if (dataType) {
        return Conditions(dataType.dataType).map((condition) => ({
          id: this.$nano.id(),
          label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
            condition.split(" ")[1] ? condition.split(" ")[1] : ""
          }`,
          value: condition.split(" ")[0],
        }));
      }
    },

    addFilterGroup(groupIdx = 0) {
      this.security.filterBy.splice(groupIdx, 0, {
        id: this.$nano.id(),
        filters: [
          {
            id: this.$nano.id(),
            criteria: "",
            condition: "",
            value: "",
            levelId: "",
          },
        ],
        groupCondition: this.security.filterBy.length ? "" : "",
      });
    },

    removeFilterGroup(groupIdx) {
      if (groupIdx > 0) {
        this.security.filterBy.splice(groupIdx, 1);
      } else {
        this.$emit("input", false);
      }
    },

    addFilter(groupIdx) {
      this.security.filterBy[groupIdx].filters.push({
        id: this.$nano.id(),
        criteria: "",
        condition: "",
        value: "",
        levelId: "",
      });
    },

    removeFilter(groupIdx, filterIdx) {
      this.security.filterBy[groupIdx].filters.splice(filterIdx, 1);
    },

    applyDataType(groupIdx, filterIdx, name, value) {
      this.security.filterBy[groupIdx].filters[filterIdx].value = "";
      this.security.filterBy[groupIdx].filters[filterIdx].condition = "";
      this.security.filterBy[groupIdx].filters[filterIdx].condition =
        "IS_EQUALS_TO";

      this.security.filterBy[groupIdx].filters[filterIdx].dataType = "";

      let type = this._columns.find((item) => item.label === name);

      if (type) {
        this.security.filterBy[groupIdx].filters[filterIdx].dataType =
          type.dataType;
        this.security.filterBy[groupIdx].filters[filterIdx].levelId =
          type.levelId;
        if (value) {
          this.security.filterBy[groupIdx].filters[filterIdx].value = value;
        }
      }
    },

    clearFilter(boolean) {
      this.security.id = 0;
      this.security.name = "";
      this.security.fieldId = "";
      this.security.duration = 0;
      this.security.durationType = "Day";
      this.security.notificationDate = "field_date";
      this.security.notification = {
        duration: 0,
        durationType: "Day",
        user: [],
        group: [],
        mailSubject: {
          text: "",
          content: "",
        },
        mailBody: {
          text: "",
          content: "",
        },
      };
      this.security.filterBy = [];
      this.security.conditionJson = [];
      this.security.action = "";
      this.notify = false;

      this.activeTab = "CONDITIONS";

      if (boolean) {
        this.addFilterGroup();
      }
    },

    groupCondition(condition) {
      this.security.filterBy[this.security.filterBy.length - 1].groupCondition =
        condition;
    },

    checkOptions(name) {
      let column = this.columns.find((column) => column.name === name);
      if (column) {
        if (column.apiLoad) {
          return true;
        }
      }
      return false;
    },

    getOptions(name) {
      if (
        name === "createdBy" ||
        name === "modifiedBy" ||
        name === "owner" ||
        name === "coordinator"
      ) {
        return this.userList;
      }
      return [];
    },

    getIcon(id) {
      let columns = this.columns;
      let largestValue = 0;
      let icon = "";
      for (const column of columns) {
        if (column.level > largestValue) {
          largestValue = column.level;
        }
      }

      if (id !== 0 && id < largestValue) {
        icon = "mdi-folder";
      } else if (id == largestValue) {
        icon = "eva-file-text-outline";
      } else icon = "mdi-label-outline";

      return icon;
    },

    getColor(id) {
      let columns = this.columns;

      let largestValue = 0;

      let color = "";

      for (const column of columns) {
        if (column.level > largestValue) {
          largestValue = column.level;
        }
      }

      if (id !== 0 && id < largestValue) {
        color = "amber";
      } else if (id == largestValue) {
        color = "secondary";
      }

      return color;
    },

    closeSheet() {
      this.$emit("input", false);
      this.clearFilter(false);
      this.$emit("refresh", this.repositoryId);
      this.$emit("reset");
    },

    async getUserList() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: user.id,
          // value: parseInt(user.id, 10),
        }));
    },

    async getGroupList() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
          // value: parseInt(group.id, 10),
        }));
    },

    async apply() {
      if (this.notificationDate === "field_date") {
        this.security.duration = 0;
        this.security.durationType = "Day";
      }

      const formField = await this.$refs.form.validate();

      if (!formField) {
        return;
      }

      let invalidFields = 0;

      for (const condition of this.$refs.condition) {
        const isValid = await condition.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }

      const securityField = await this.$refs.security.validate();
      if (!securityField) {
        return;
      }

      this.security.filterBy[0].groupCondition = "";

      if (this.security.filterBy.length) {
        if (!this.security.filterBy[0].filters.length) {
          this.security.filterBy = [];
        }
        this.security.filterBy.forEach((group) => {
          if (group.filters.length) {
            group.filters.forEach((row) => {
              if (row.dataType === "SINGLE_SELECT") {
                row.value = JSON.stringify(row.arrayValue);
              }
            });
          }
        });
      }

      this.security.conditionJson = JSON.stringify(this.security.filterBy);
      if (this.notify) {
        this.security.notifyJson = JSON.stringify(this.security.notification);
      } else {
        this.security.notifyJson = "";
      }
      this.security.fieldId = Number(this.security.fieldId);

      const { error } = await repository.addRetention(this.security);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("New retention added succesfully");
      this.closeSheet();
      this.$emit("refresh", this.repositoryId);
    },

    async save() {
      const formField = await this.$refs.form.validate();

      if (!formField) {
        return;
      }

      let invalidFields = 0;

      for (const condition of this.$refs.condition) {
        const isValid = await condition.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }

      const securityField = await this.$refs.security.validate();
      if (!securityField) {
        return;
      }

      this.security.filterBy[0].groupCondition = "";

      if (this.security.filterBy.length) {
        if (!this.security.filterBy[0].filters.length) {
          this.security.filterBy = [];
        }
      }

      this.security.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify(row.arrayValue);
            }
          });
        }
      });

      this.security.conditionJson = JSON.stringify(this.security.filterBy);
      if (this.notify) {
        this.security.notifyJson = JSON.stringify(this.security.notification);
      } else {
        this.security.notifyJson = "";
      }

      const { error } = await repository.updateRetention(
        this.security.id,
        this.security
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.closeSheet();
      this.$alert.success("Retention updated succesfully");
      this.$emit("refresh", this.repositoryId);

      this.clearFilter(false);
    },
  },
};
</script>

<style lang="scss" scoped>
#tab-settings {
  // .field {
  //   margin: 16px 16px 24px 16px;
  // }

  .content {
    flex: 1;
    padding: 16px;
    border-right: 1px solid var(--divider-color);
  }

  .filter-group {
    border: 1px solid var(--divider-color);
    border-radius: 4px;

    .header {
      display: flex;
      align-items: center;
      padding: 8px 8px 8px 16px;

      .label {
        @extend .text-sm;
        color: var(--icon-color);
        flex: 1;
      }
    }

    .filter {
      display: flex;
      padding: 0px 8px 16px 16px;

      :deep #field div {
        width: 137px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 0px;
      }
    }

    .remove-filter {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      color: var(--secondary);
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tabs {
    width: 68px;
    padding: 16px;
  }
}

.mail-class {
  margin: 0px !important;
}
</style>
